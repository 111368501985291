.login-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 50%;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.login-content {
    text-align: center;
}
.login-icon .svg-inline--fa {
    color: #e1e1e1;
    font-size: 100px;
}
.login-content h2 {
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    margin: 10px 0 20px;
}
.error-message {
    color: red;
    margin-top: 10px;
}
.login-content .login-input {
    position: relative;
}
.login-content .login-input .svg-inline--fa {
    position: absolute;
    font-size: 14px;
    color: #999;
    top: 20px;
    left: 15px;
}
.login-content .login-input input {
    padding: 8px 10px 8px 40px!important;
    border: 1px solid #ebf1ff;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    height: 54px;
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
}
.close-button {
    background: none;
    border: none;
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    padding: 0;
    background-color: #000;
    border-radius: 30px;
    width: 38px;
    line-height: 30px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: 4px solid #fff;
}
.close-button img {
    width: 20px;
    height: 20px;
}
.login-content .btn-primary {
    border-color: #16A34A;
    padding: 12px 20px;
    border-radius: 10px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-width: 2px;
}
.btn-link {
    color: #333;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding-top: 17px;
    display: inline-block;
}
@media (max-width: 767px) {
    .login-popup {
        width: 80%;
    }
}
.welcometext {
    margin-right:15px;
}
.resetLink {
    margin:10px;
}