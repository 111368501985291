html {
    height: 100%;
    box-sizing: border-box;
}
body {
    position: relative;
    margin: 0;
    padding-bottom: 80px;
    min-height: 100%;
}
.container {
    margin: auto;
    max-width: 100%;
    width: 1380px;
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: transparent;
    color: #fff;
    display: flex;
    align-items: center;
    /*padding: 0 20px;*/
    padding: 0;
    z-index: 1;
    /*box-shadow: 1px 0 20px rgba(0,0,0,.1);*/
}
.inner-header {
    position: relative;
}
.logo {
    /*flex: 1;*/
}
.logo img {
    height: 60px;
}
.login-button {
    /*flex: 1;*/
    text-align: right;
}
.feature-image {
    background: url('/public/images/slider.png') center center no-repeat;
    background-size: cover;
    height: 650px;
    position: relative;
    /*display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;*/
    text-align: center;
    color: #fff;
}
.feature-image h2 {
    font-size: 36px;
    margin:0;
    padding: 210px 0 0;
}
.feature-image h2 span {
    font-family: 'Caveat', cursive;
    font-size: 80px;
    font-weight: 500;
}
/*.feature-image-upper {
    width: 300px;
    height: 200px;
    position: absolute;
    top: 50px;
    right: 30%;
    background: url('/public/images/slider-upper.png') center center no-repeat;
    background-size: contain;
}*/
.box {
    margin-top: 40px;
    text-align: center;
}
.box img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}
.login-button .btn-primary {
    background-color: #0049ed;
    box-shadow: transparent;
    color: #fff;
    padding: 10px 20px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    border: none;
    border-radius: 8px;
}
.form-wrap {
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    bottom: 150px;
    text-align: center;
    width: 90%;
    left: 5%;
    padding: 15px;
}
.form-inline {
    /*padding: 8px;*/
}
.form-inline .form-group {
    /*margin-right: 10px;
    padding-left: 20px;
    padding-right: 20px;*/
    border-left: 2px solid #f2f2f2;
    margin: 7px 0;
}
.form-inline .form-group.selectbox-wrap {
    border-left: 0px transparent;
}
.form-inline .form-group.selectbox-wrap .css-13cymwt-control {
    border: none;
}
.form-inline .form-group .css-1u9des2-indicatorSeparator {
    display: none !important;
}
.selectbox-wrap .form-control {
    padding: 0;
    border: 0;
}
.selectbox-wrap .fa-location-pin {
    width: 15px;
}
.selectbox-wrap .form-control .css-1xc3v61-indicatorContainer {
    padding-top: 6px;
    padding-bottom: 6px;
}
.selectbox-wrap .form-control .css-1fdsijx-ValueContainer {
    padding-bottom: 0;
    padding-top: 0;
}
.selectbox-wrap .form-control .css-1jqq78o-placeholder {
    color: #000 !important;
    font-weight: 600;
    text-align: left;
}
.selectbox-wrap .form-control .css-13cymwt-control {
    min-height: 35px;
}
.css-t3ipsp-control,
.css-15lsz6c-indicatorContainer:hover,
.css-t3ipsp-control:hover,
.css-13cymwt-control:hover,

.css-15lsz6c-indicatorContainer:visited,
.css-t3ipsp-control:visited,
.css-13cymwt-control:visited {
    border: none !important;
    box-shadow: 0 0 0 0px #fff !important;
}
.form-wo-border .form-group .form-control {
    border: 0;
    box-shadow: inset 0 0px 0px rgba(0,0,0);
    border-radius: 0;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    width: calc(100% - 30px);
    margin-bottom: 0;
}
.form-wo-border .form-group .icon {
    color: #0049ed;
    position: relative;
    top: 2px;
}
.form-wo-border .btn-primary {
    border-color: #16A34A;
    padding: 12px 0;
    text-transform: uppercase;
    border-radius: 10px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
}
.usp-wrap {
    position: absolute;
    bottom: 45px;
    width: 90%;
    left: 5%;
}
.width-80 {
    width: 80%;
}
.margin-auto {
    margin-left: auto;
    margin-right: auto;
}
.usp-wrap ul {
    margin: 0;
    padding: 0;
}
.usp-wrap ul li {
    list-style: none;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    display: inline-block;
    line-height: 20px;
    padding: 0 30px;
    text-align: left;
}
.usp-wrap ul li .svg-inline--fa {
    background-color: rgba(255, 255, 255, .3);
    width: 20px;
    height: 20px;
    line-height: 60px;
    padding: 20px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
    margin-top: -10px;
}
.description-box {
    border: 1px solid #E0e0e0;
    border-radius: 10px;
    padding: 40px;
}
.description-box .icon {
    width: 80px;
    height: 80px;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.des-icon1 {
    background-image: url('/public/images/group38004.png');
}
.description-box h3 {
    color: #333;
    font-size: 16px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    padding: 10px 0;
    font-weight: 600;
    margin: 0;
}
.description-box p {
    color: #666;
    font-size: 14px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    padding: 0;
}
.form-wrap .invalid-feedback {
    color: #dc3545;
    left: 48px;
    bottom: -7px;
    margin: 0;
    font-size: 12px;
    right: 0;
    text-align: left;
    font-family: 'Inter', sans-serif;
}
.p-10 {
    padding: 10px;
}
.p-20 {
    padding: 20px;
}
.p-30 {
    padding: 30px;
}
.p-40 {
    padding: 40px;
}
.pt-10 {
    padding-top: 10px;
}
.pt-20 {
    padding-top: 20px;
}
.pt-30 {
    padding-top: 30px;
}
.pt-40 {
    padding-top: 40px;
}

.pb-10 {
    padding-bottom: 10px;
}
.pb-20 {
    padding-bottom: 20px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pb-40 {
    padding-bottom: 40px;
}

.pl-10 {
    padding-left: 10px;
}
.pl-20 {
    padding-left: 20px;
}
.pl-30 {
    padding-left: 30px;
}
.pl-40 {
    padding-left: 40px;
}

.pr-10 {
    padding-right: 10px;
}
.pr-20 {
    padding-right: 20px;
}
.pr-30 {
    padding-right: 30px;
}
.pr-40 {
    padding-right: 40px;
}


.m-10 {
    margin: 10px;
}
.m-20 {
    margin: 20px;
}
.m-30 {
    margin: 30px;
}
.m-40 {
    margin: 40px;
}
.mt-0 {
    margin-top: 0px !important;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-40 {
    margin-bottom: 40px !important;
}

.ml-10 {
    margin-left: 10px;
}
.ml-20 {
    margin-left: 20px;
}
.ml-30 {
    margin-left: 30px;
}
.ml-40 {
    margin-left: 40px;
}

.mr-10 {
    margin-right: 10px;
}
.mr-20 {
    margin-right: 20px;
}
.mr-30 {
    margin-right: 30px;
}
.mr-40 {
    margin-right: 40px;
}

.footer {
    background-color: #333;
    box-shadow: 1px 0 20px rgba(0,0,0,.1);
    margin: 0;
    padding: 30px 0;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}
.footer-links {
    margin: 0;
    padding: 0;
}
.footer-links li {
    list-style: none;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #fff;
    border-right: 1px solid rgba(255,255,255,.3);
    padding: 0 10px;
}
.footer-links li:first-child {
    padding-left: 0;
}
.footer-links li:last-child {
    border-right: none;
}
.footer-links li a {
    color: #fff;
}
.copyright {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #999;
    padding: 0 10px;
    text-align: right;
}
.form-wrap .form-inline .form-group.selectbox-wrap {
    text-align: left;
}
/*.css-1nmdiq5-menu {
    top: 47px !important;
    left: -28px;
    border-radius: 15px !important;
    min-width: 250px;
}*/
.add-plate-pop h2 {
    text-align: left;
}
.add-plate-pop .form-group {
    text-align: left;
    margin-bottom: 20px;
}
.add-plate-pop .form-group .form-control {
    padding: 8px 10px !important;
    border: 1px solid #ebf1ff;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    height: 54px;
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
}
.add-plate-pop .form-group .form-select__control {
    padding: 8px 10px !important;
    border: 1px solid #ebf1ff !important;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
}
.add-plate-pop .form-group .form-select__control:hover,
.add-plate-pop .form-group .form-select__control:focus,
.add-plate-pop .form-group .form-select__control:visited {
    border: 1px solid #ebf1ff !important;
}
.add-plate-pop .form-group .form-select__control input {
    height: auto !important;
    padding: 0 !important;
}
.header .nav.nav-pills {
    background-color: #0953fa;
    padding: 22px;
}
.header .nav.nav-pills .dropdown .dropdown-toggle {
    padding: 0;
    color: #fff;
}
.header .nav.nav-pills .dropdown .dropdown-toggle:hover,
.header .nav.nav-pills .dropdown .dropdown-toggle:visited,
.header .nav.nav-pills .dropdown .dropdown-toggle:focus,
.header .nav.nav-pills .dropdown.open > a,
.header .nav.nav-pills .dropdown.open > a:focus, 
.header .nav.nav-pills .dropdown.open > a:hover {
    background-color: transparent !important;
}
.header .nav.nav-pills .dropdown .dropdown-toggle .caret {
    border-width: 6px;
}
.header .nav.nav-pills .dropdown .dropdown-toggle .glyphicon-user {
    background-color: #0049ed;
    border-radius: 50%;
    width: 35px;
    line-height: 35px;
    text-align: center;
    margin-right: 10px;
}
.header .nav.nav-pills .dropdown .dropdown-menu {    
    right: -23px;
    left: auto;
    top: 57px;
    margin: 0;
    border-radius: 0 0 10px 10px;
    border: none;
}
.header .nav.nav-pills .dropdown .dropdown-menu li a {
    padding: 5px 10px;
}
.user-profile {
    display: flex;
    align-items: center;
    padding: 10px;
    font-family: 'Inter', sans-serif;
    border-bottom: 1px solid #f1f1f1;
}
.user-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.user-info {
  display: flex;
  flex-direction: column;
}
.user-name {
  font-weight: 600;
  margin-bottom: 0px;
  color: #024430;
  line-height: 18px;
  font-size: .9em;
  text-transform: uppercase;
}
.user-email {
  font-size: .9em;
  color: #777;
}
.header .nav.nav-pills .dropdown-item {
  font-family: 'Open Sans', sans-serif;
  color: #024430;
  font-size: .9em;
  padding: 11px 10px 8px 10px;
  border-bottom: 1px solid #f1f1f1;
  text-transform: uppercase;
  display: block;
}
.header .nav.nav-pills .dropdown-item:last-child {
    border-bottom: none;
}
.header .nav.nav-pills .dropdown-item:hover {
    text-decoration: none;
    background-color: #f9f9f9;
}
.header .nav.nav-pills .dropdown-item .glyphicon {
    color: #ccc;
    padding-right: 5px;
}

/* StateMunicipalityDropdown.css */

/* Style for the selected state area */
.selected-state {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #f4f4f4; /* Background color for the selected state area */
  border: 1px solid #ccc; /* Border for the selected state area */
  border-radius: 4px; /* Rounded corners */
  color:#333;
  font-weight: bold;
  cursor: pointer;
}
.btn-back {
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 10px;
}
.selected-state-label {
  margin-left: 5px; /* Add some margin between the "Back" button and the selected state */
  font-weight: bold;
}

.tate-section {
    color:#333 !important;
}



/* StateMunicipalityDropdown.css */

/* Style the state-section */
.state-section {
    color: #000 !important;
  /* Add your custom styles here */
}

/* Style the municipality-section */
.municipality-section {
    color: #fff !important;
  /* Add your custom styles here */
}

/* Style the custom-select class prefix */
.custom-select__control {
    color: #fff !important;
  /* Add your custom styles here */
}

/* Style the custom-select class prefix for focused elements */
.custom-select__control--is-focused {
    color: #fff !important;
  /* Add your custom styles here */
}

/* Style the custom-select class prefix for selected values */
.custom-select__single-value {
    color: #fff !important;
  /* Add your custom styles here */
}

/***************** home page select state start ****************/
.form-wrap .form-inline .form-group.selectbox-wrap {
    padding-top: 0;
    padding-bottom: 0;
}
.selectbox-wrap .municipality-section {
    display: flex;
    flex-direction: row-reverse;
    align-content: flex-start;
    text-align: left;
    float: left;
    width: 100%;
    margin-top: -3px;
}
.selectbox-wrap .municipality-section .css-b62m3t-container,
.selectbox-wrap .municipality-section .selected-state {
    width: 50%;
}
.selectbox-wrap .municipality-section .custom-select__indicator {
    padding: 2px;
}
.state-section .css-b62m3t-container .custom-select__control {
    margin-top: -3px !important;
    min-height: 35px;
}
.state-section .custom-select__control .custom-select__indicators .custom-select__indicator-separator {
    display: none;
}
.state-section .form-select {
}
.state-section .form-select__control,
.selectbox-wrap .municipality-section .form-select__control {
    margin-top: 4px !important;
}
.selectbox-wrap .municipality-section .selected-state {
    margin-top: 0;
    border: none;
    border-right: 1px solid #a2b3dd;
    border-radius: 0;
    background-color: transparent;
    margin-right: 5px;
}
.selectbox-wrap .municipality-section .selected-state .icon {
    background-color: #0049ed;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    padding: 4px 5px;
    top: 0px;
}
.selectbox-wrap .municipality-section .selected-state .selected-state-label {
    box-shadow: inset 0 0px 0px rgba(0,0,0);
    border-radius: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #e1e1e1;
    font-weight: normal;
}
/***************** home page select state end ****************/
/*************** Municipality-section start ****************/

.step2 .municipality-section,
.ReactModalPortal .municipality-section {
    display: flex;
    flex-direction: column-reverse;
}
.step2 .state-section .custom-select__control,
.ReactModalPortal .state-section .custom-select__control,
.step2 .municipality-section .css-b62m3t-container > .custom-select__control,
.ReactModalPortal .municipality-section .css-b62m3t-container > .custom-select__control {
    background-color: #f3f3f3;
    border: none !important;
    box-shadow: inset 0 0 0 rgba(255,255,255,0) !important;
    border-radius: 10px;
    height: 53px;
}
.step2 .municipality-section .custom-select__control .custom-select__single-value,
.ReactModalPortal .municipality-section .custom-select__control .custom-select__single-value {
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
}
.step2 .municipality-section .custom-select__control.css-13cymwt-control .custom-select__indicators .custom-select__indicator-separator,
.ReactModalPortal .municipality-section .custom-select__control.css-13cymwt-control .custom-select__indicators .custom-select__indicator-separator {
    display: none;
}
.step2 .municipality-section .custom-select__control .custom-select__value-container .custom-select__placeholder,
.ReactModalPortal .municipality-section .custom-select__control .custom-select__value-container .custom-select__placeholder {
    font-size: 18px;
    color: #888;
    font-family: 'Inter', sans-serif;
}
.step2 .municipality-section .selected-state,
.ReactModalPortal .municipality-section .selected-state {
    margin-top: 0;
    margin-bottom: 15px;
    background-color: #f3f3f3;
    border: 1px solid #ebf1ff !important;
    box-shadow: inset 0 0 0 rgba(255,255,255,0) !important;
    border-radius: 10px;
    height: 53px;
}
.step2 .state-section .custom-select__control .custom-select__placeholder,
.ReactModalPortal .state-section .custom-select__control .custom-select__placeholder {
    font-size: 18px;
    color: #888888;
    font-family: 'Inter', sans-serif;
}
.step2 .selected-state .icon,
.ReactModalPortal .selected-state .icon {
    background-color: #0049ed;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    padding: 4px 5px;
    top: 0px;
    margin-left: 10px;
}
.step2 .selected-state .selected-state-label,
.ReactModalPortal .selected-state .selected-state-label {
    font-size: 18px;
    color: #888;
    font-family: 'Inter', sans-serif;
    font-weight: normal;
}
/*************** Municipality-section end ****************/
/*************** tabs start *****************/
.tabber-wrap {
    border: 1px solid #dcdcdc;
    border-radius: 0 15px 15px 15px;
    width: 100%;
    margin: auto;
}
.tab-buttons .tab-button {
    display: inline-block;
    padding: 0;
    border: 1px solid #dcdcdc;
    border-bottom: none;
    width: 180px;
    height: 60px;
    border-radius: 10px 10px 0 0;
    margin-right: 10px;
    position: relative;
    background-color: #dcdcdc !important;
    text-indent: -3333px !important;
}
.tab-buttons .tab-button.ach-tab {
    background: url('/public/images/ACH-grey-big.png') center center no-repeat;
    background-size: 80px auto;
}
.tab-buttons .tab-button.card-tab {
    background: url('/public/images/Card-grey-big.png') center center no-repeat;
    background-size: 110px auto;
}
.tab-buttons .tab-button.active {
    border-color: #dcdcdc;
    position: relative;
    top: 1px;
    background-color: #fff !important;
}
.tab-buttons .tab-button.ach-tab.active {
    background: url('/public/images/ACH-current-big.png') center center no-repeat;
    background-size: 80px auto;
}
.tab-buttons .tab-button.card-tab.active {
    background: url('/public/images/Card-current.png') center center no-repeat;
    background-size: 110px auto;
}
/*************** tabs end *****************/
.clear-both {
    clear: both;
}
/* Custom styles for tabs */
.react-tabs {
    font-family: 'Inter', sans-serif;
}
/* Style tab list */
.react-tabs__tab-list {
    display: flex;
    list-style-type: none;
    padding: 0 !important;
    margin: 0 !important;
    border-bottom: none !important;
}
/* Style individual tab */
.react-tabs__tab {
    padding: 15px 20px !important;
    cursor: pointer;
    color: #757575;
    border: 1px solid #dcdcdc !important;
    border-bottom: none;
    margin-bottom: 0;
    background-color: #dcdcdc;
    border-radius: 10px 10px 0 0 !important;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
}
/* Add some spacing between tabs */
.react-tabs__tab:not(:last-child) {
    margin-right: 10px;
}

/* Change background color on hover */
.react-tabs__tab:hover {
    background-color: #ddd;
}

/* Style the active tab */
.react-tabs__tab.react-tabs__tab--selected {
    background-color: #fff;
    border-bottom: 1px solid #fff !important;
    color: #044bec;
}
.react-tabs__tab .svg-inline--fa {
    font-size: .8em;
    padding-right: 5px;
}
/* Style tab content panels */
.react-tabs__tab-panel {
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
}
.btn-secondary {
  background-color: #ccc;
  color: #333;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.btn-secondary:hover {
  background-color: #999;
}
.form-design .form-group {
    margin-bottom: 20px;
}
.form-design .form-group label {
    font-family: 'Inter', sans-serif;
    font-size: 1em;
}
.form-design .form-group .form-control {
    padding: 8px 10px !important;
    border: 1px solid #ebf1ff;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    height: 54px;
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
}
.form-design .btn-primary {
    border-color: #16A34A;
    padding: 12px 20px;
    border-radius: 10px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-width: 2px;
}
/*********************cards start *********************/
.card-wrap {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    flex-direction: row;
    flex-grow: 2;
}
.card-inner {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 3px rgb(60, 72, 88, 0.4);
    width: calc(100% / 2 - 15px);
    margin-bottom: 30px;
    position: relative;
    /*padding-bottom: 60px;*/
}
.card-inner .card-header {
    border-bottom: 1px dashed #e2e8f0;
    padding: 15px;
    float: left;
    width: 100%;
}
.card-inner .card-footer {
    border-top: 1px dashed #e2e8f0;
    padding: 15px;
    float: left;
    width: 100%;
    position: absolute;
    bottom: 0;
}
.card-inner .card-header h4 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 32px;
}
.card-inner .btn-primary {
    border-color: #16A34A;
    padding: 5px 10px;
    border-radius: 5px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-width: 1px;
}
.card-inner .btn-primary.view-all {
    border-color: #000;
    background: rgb(51,51,51);
    background: linear-gradient(343deg, rgba(51,51,51,1) 0%, rgba(51,51,51,1) 50%, rgba(0,0,0,1) 100%);
}
.card-inner .btn-primary.add-more {
    border: 1px solid rgb(22,163,74);
    background: transparent;
    color: rgb(22,163,74);
    margin-right: 7px;
}
.card-inner .card-body {
    padding: 15px;
    clear: both;
}
.card-inner .table thead th {
    background-color: #e1e1e1;
    padding: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: bold;
}
.card-inner .table tbody td {
    border-top: 1px dashed #e2e8f0;
    padding: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}
.analyse-box {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 3px rgb(60, 72, 88, 0.4);
    padding: 20px;
    position: relative;
    height: 100px;
}
.analyse-box .heading {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
    color: #ccc;
    padding-bottom: 5px;
    font-weight: 700;
}
.analyse-box .value {
    font-family: 'Inter', sans-serif;
    font-size: 28px;
    text-align: left;
    color: #333;
    font-weight: 700;
}
.analyse-box .glyphicon {
    position: absolute;
    font-size: 30px;
    color: #666;
    top: 20px;
    right: 20px;
}
/*********************cards end *********************/
/********************* menu link start ******************/
.menu-links {
    padding-left: 20px;
    flex: 1;
}
.menu-links .nav li {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
}
.menu-links .nav li a {
    color: #fff;
    padding: 30px 15px;
    background-color: transparent;
}
.menu-links .nav li a .caret {
    border-width: 6px;
    margin-left: 5px;
}
.menu-links .nav li a .glyphicon {
    margin-right: 3px;
    font-size: 15px;
}
.menu-links .nav li a:hover,
.menu-links .nav li a:focus,
.menu-links .nav li a:visited  {
    background-color: transparent;
}
.menu-links .nav li.dropdown .dropdown-menu {
    border: none;
}
.menu-links .nav li.dropdown .dropdown-menu li a {
    padding: 5px 10px;
    color: #333;
    font-size: 14px;
}
.account-page {
    background-color: #f3f4f6;
}
/********************* menu link end ******************/
/********************* Breadcrumb Start********************/
.breadcrumb-wrap {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #666;
    font-weight: 400;
    padding: 0;
    margin: 0;
}
.breadcrumb-wrap li {
    list-style: none;
    display: inline-block;
}
.breadcrumb-wrap li a {
    color: #666;
}
.breadcrumb-wrap li.active {
    color: #0953fa;
    font-weight: 600;
}
.breadcrumb-wrap li:after {
    content: "/";
    color: #ccc;
    padding: 0 10px;
}
.breadcrumb-wrap li:last-child:after {
    content: "";
}
/********************* Breadcrumb end********************/
/********************* reset password start **********************/
.normal-form .form-group {
    text-align: left;
    margin-bottom: 20px;
}
.normal-form .form-group .input-group {
    display: block;
    position: relative;
}
.normal-form .form-group .form-control {
    padding: 8px 10px 8px 50px !important;
    border: 1px solid #ebf1ff;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    height: 54px;
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
    z-index: 1;
}
.normal-form .form-group .form-select__control {
    padding: 8px 10px !important;
    border: 1px solid #ebf1ff !important;
    border-radius: 10px;
    background-color: #f3f3f3;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
    font-size: 18px;
    color: #333;
    font-family: 'Inter', sans-serif;
    width: 100%;
    margin: 0 0 15px;
    z-index: 1;
}
.normal-form .form-group .form-select__control:hover,
.normal-form .form-group .form-select__control:focus,
.normal-form .form-group .form-select__control:visited {
    border: 1px solid #ebf1ff !important;
}
.normal-form .form-group .form-select__control input {
    height: auto !important;
    padding: 0 !important;
}
.normal-form .form-group .input-group .input-group-addon {
    position: absolute;
    z-index: 2;
    background: transparent;
    border: none;
    left: 5px;
    top: 12px;
    color: #ccc;
    font-size: 20px;
}
.normal-form .form-group .help-block {
    clear: both;
    top: -10px;
    position: relative;
    color: red;
}
.normal-form .btn-primary {
    border-color: #16A34A;
    padding: 12px 20px;
    border-radius: 10px;
    background: rgb(22,163,74);
    background: linear-gradient(343deg, rgba(22,163,74,1) 0%, rgba(22,163,74,1) 50%, rgba(109,197,138,1) 100%);
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-width: 2px;
}
/********************* reset password end **********************/
.ReactModalPortal {
    z-index: 3;
    position: relative;
}
/***************** slider start ********************/
.left.carousel-control,
.right.carousel-control {
    background: transparent;
    width: 10%;
}
.left.carousel-control .control-icon {
    position: absolute;
    top: 50%;
    width: 31px;
    height: 29px;
    left: calc(50% - 15px);
    margin-top: -10px;
    background: url('/public/images/indicator-left.png') center center no-repeat;
}
.right.carousel-control .control-icon {
    position: absolute;
    top: 50%;
    width: 31px;
    height: 29px;
    right: calc(50% - 15px);
    margin-top: -10px;
    background: url('/public/images/indicator-right.png') center center no-repeat;
}
.carousel-caption {
    left: 10%;
    top: 20%;
    bottom: auto;
    right: auto;
    padding: 0;
    text-align: left;
}
.carousel-caption h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    text-align: left;
    text-shadow: none;
    font-weight: 400;
    margin: 0 0 20px;
}
.carousel-caption h1 > span {
    font-size: 84px;
    display: block;
}
.carousel-caption a.btn-primary {
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(22,164,74,1) 0%, rgba(34,196,94,1) 100%);
    border-radius: 0;
    border: none;
    padding: 10px 55px 10px 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    box-shadow: 7px 7px 9px rgba(0,0,0,.1);
    transition: transform .8s; /* Animation */
}
.carousel-caption a.btn-primary:hover {
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(34,196,94,1) 0%, rgba(22,164,74,1) 100%);
}
.carousel-caption a.btn-primary:after {
    content: "";
    background-image: url('/public/images/indicator-right.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 26px;
    position: absolute;
    right: 15px;
    top: calc(50% - 13px);
}
.carousel-inner > .item {
    background-color: #0b49d9;
    text-align: right;
}
.carousel-inner > .item img {
    width: 100%;
    max-height: 450px;
    object-fit: cover;
}
/***************** slider end ********************/
/***************** Home Form start****************/
.renewal-form-wrap {
    background-color: #f1f2f2;
    position: relative;
    height: 340px;
}
.renewal-form-inner {
    background-color: #fff;
    border-radius: 30px;
    padding: 50px;
    border: 2px solid #e6e7e8;
    position: absolute;
    width: 80%;
    left: 10%;
    top: -40px;
}
.renewal-form-inner .form-wrap,
.renewal-form-inner .usp-wrap {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
}
.renewal-form-inner .form-wrap {
    background-color: #0348ed;
    width: 100%;
    border-radius: 15px;
    /*border: 1px solid #c7cfe1;*/
    box-shadow: 5px 5px 9px rgba(0,0,0,.2);
}
.renewal-form-inner .form-wrap .form-inline .form-group {
    margin: 0 !important;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .css-13cymwt-control,
.renewal-form-inner .form-wo-border .form-group .form-control {
    background-color: transparent;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    height: auto;
}
.renewal-form-inner .form-wo-border .form-group .form-control::-moz-placeholder {
    color: #fff;
    opacity: 1;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}
.renewal-form-inner .form-wo-border .form-group .form-control:-ms-input-placeholder {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}
.renewal-form-inner .form-wo-border .form-group .form-control::-webkit-input-placeholder {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}
.renewal-form-inner .form-wo-border .form-group .icon {
    color: #fff;
}
.renewal-form-inner .form-wo-border .btn-primary {
    background:#ffffff url('/public/images/submit-icon.png') no-repeat;
    background-position: right 7px top 10px;
    background-size: 24px;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: #16a34a;
    padding: 4px 35px 4px 10px !important;
    width: auto;
    font-size: 15px;
    box-shadow: 7px 7px 9px rgba(0,0,0,.1);
    border: none;
}
.renewal-form-inner .form-inline .form-group {
    border-left: 1px solid #a2b3dd;
}
.renewal-form-inner .form-inline .form-group:first-child {
    border-left: none;
}
.renewal-form-inner .state-section  .custom-select__control {
    background-color: transparent;
}
.renewal-form-inner .form-inline .custom-select__indicators {
    height: auto;
}
.renewal-form-inner .form-inline .custom-select__indicator {
    padding: 3px !important;
}
.renewal-form-inner .form-inline .custom-select__indicator svg {
    color: #fff;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: auto;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control.custom-select__control--menu-is-open,
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control.custom-select__control--is-focused {
    background-color: transparent !important;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container {
    padding-top: 0;
    padding-bottom: 0;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container .custom-select__placeholder {
    color: #fff !important;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container .custom-select__input-container {
    margin: 0px;
    margin-top: 7px;
}
.renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__menu {
    margin-top: 15px;
    background-color: #0348ed;
    border-radius: 0 0 10px 10px;
}
.renewal-form-inner .usp-wrap {
    width: 80%;
    margin: 50px auto 0;
}
.renewal-form-inner .usp-wrap ul li {
    width: 33.33%;
    text-align: center;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
}
.renewal-form-inner .usp-wrap ul li .svg-inline--fa {
    float: none !important;
    display: block !important;
    margin: auto !important;
    margin-bottom: 10px !important;
    background-color: #939598;
    color: #fff;
}
.renewal-form-inner .usp-wrap ul li:nth-child(even) .svg-inline--fa {
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(22,164,74,1) 0%, rgba(34,196,94,1) 100%);
}
/***************** Home Form end****************/
/***************** Home marketing container start****************/
.mkt-wrap h2 {
    font-family: 'Poppins', sans-serif;
    color: #0049ed;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}
.mkt-wrap h2 > span {
    font-size: 14px;
    color: #76777a;
    display: block;
    font-weight: 400;
}
.mkt-wrap .description-box {
    border: 2px solid #231f20;
    padding: 30px 40px 15px;
    position: relative;
    transition: transform .4s; /* Animation */
}
.mkt-wrap .description-box:hover {
    border-color: #0348ed;
    background-color: #0348ed;transform: scale(1.2);
    z-index: 444;
}
.mkt-wrap .description-box .icon {
    position: absolute;
    top: -30px;
    z-index: 1;
    background-color: #939598;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    left: calc(50% - 30px);
    border: 2px solid #939598;
}
.mkt-wrap .description-box:hover .icon {
    background-color: #0348ed;
    border-color: #fff;
}
.mkt-wrap .description-box .icon.des-icon1 {
    background-image: url('/public/images/mkt1.png');
    background-size: 40px auto;
}
.mkt-wrap .description-box .icon.des-icon2 {
    background-image: url('/public/images/mkt2.png');
    background-size: 40px auto;
}
.mkt-wrap .description-box .icon.des-icon3 {
    background-image: url('/public/images/mkt3.png');
    background-size: auto 40px;
}
.mkt-wrap .description-box h3 {
    font-family: 'Poppins', sans-serif;
    color: #1c2733;
    font-size: 18px;
    font-weight: 400;
}
.mkt-wrap .description-box:hover h3 {
    color: #fff;
}
.mkt-wrap .description-box p {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
.mkt-wrap .description-box:hover p {
    color: #fff;
}
/***************** Home marketing container end****************/

.addMoreButtonContainer {
    margin-bottom: 15px;
    float:right;
}
.text-danger {
    color: #dc3545;
    font-family: 'Poppins', sans-serif;
}
.loggedin-bg {
    background-color: #0348ed;
}
.renewal-form-wrap .custom-select__menu-list > div {
    background-color: transparent;
    color: #fff;
    font-family: 'Poppins', sans-serif;
}
.custom-select__menu .custom-select__menu-list .custom-select__option:hover {
    background: #0c4adb !important;
}


/*************** react select step2 start ************/
.state-section .custom-select__menu-list > div {
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
}
.state-section .custom-select__menu .custom-select__menu-list .custom-select__option:hover {
    background: #0c4adb !important;
    color: #fff;
}
.municipality-section .custom-select__menu .custom-select__menu-list .custom-select__option,
.step2-wrap #plateTypeSelect .form-select__menu .form-select__menu-list .form-select__option,
.select-drop-wrap .form-select__menu .form-select__menu-list .form-select__option,
#plateTypeSelect .form-select__menu .form-select__menu-list .form-select__option,
#preferredMethodInput .form-select__menu .form-select__menu-list .form-select__option,
#stateSelect .form-select__menu .form-select__menu-list .form-select__option {
    color: #000;
}
.municipality-section .custom-select__menu-list > div,
.step2-wrap #plateTypeSelect .form-select__menu-list > div,
.select-drop-wrap .form-select__menu-list > div,
#plateTypeSelect .form-select__menu-list > div,
#preferredMethodInput .form-select__menu-list > div,
#stateSelect .form-select__menu-list > div {
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
}
.municipality-section .custom-select__menu .custom-select__menu-list .custom-select__option:hover,
.step2-wrap #plateTypeSelect .form-select__menu .form-select__menu-list .form-select__option:hover,
.select-drop-wrap .form-select__menu .form-select__menu-list .form-select__option:hover,
#plateTypeSelect .form-select__menu .form-select__menu-list .form-select__option:hover,
#preferredMethodInput .form-select__menu .form-select__menu-list .form-select__option:hover,
#stateSelect .form-select__menu .form-select__menu-list .form-select__option:hover {
    background: #0c4adb !important;
    color: #fff;
}
.renewal-form-wrap .municipality-section .custom-select__menu .custom-select__menu-list .custom-select__option {
    color: #fff;
}
.municipality-section .custom-select__single-value {
    color: #333 !important;
}
.renewal-form-wrap .municipality-section .custom-select__single-value {
    color: #fff !important;
}
/*************** react select step2 end ************/
/*************** statc pages start *****************/
.static-page-wrap {
    position: relative;
}
.static-page-wrap .wrap-img {
    background: url('/public/images/step2-back.jpg') no-repeat;
    background-size: cover;
    min-height: 500px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-position: top -80px left 0;
}
.static-page-wrap .inner-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    z-index: 2;
}
.static-page-wrap .inner-wrapper .white-wrapper {
    margin: 0;
    background-color: #fff;
    border-radius: 30px;
    padding: 50px;
    min-height: 500px;
    border: 2px solid #e6e7e8;
}
.static-page-wrap .inner-wrapper .white-wrapper h1,
.static-page-wrap .inner-wrapper .white-wrapper h2,
.static-page-wrap .inner-wrapper .white-wrapper h3,
.static-page-wrap .inner-wrapper .white-wrapper h4,
.static-page-wrap .inner-wrapper .white-wrapper h5 {
    font-family: 'Poppins', sans-serif;
    margin: 0 0 10px 0;
    color: #333;
    padding-top: 10px;
}
.static-page-wrap .inner-wrapper h1 {
    font-size: 48px;
}
.static-page-wrap .inner-wrapper h2 {
    font-size: 30px;
}
.static-page-wrap .inner-wrapper h3 {
    font-size: 24px;
}
.static-page-wrap .inner-wrapper h4 {
    font-size: 20px;
}
.static-page-wrap .inner-wrapper h5 {
    font-size: 14px;
}
.static-page-wrap .inner-wrapper .white-wrapper p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin: 0 0 20px 0;
    color: #666;
}
.static-page-wrap .page-head {
    color: #fff;
    text-align: center;
}
.static-page-wrap .inner-wrapper .white-wrapper {
    margin-top: 40px;
}
.static-page-wrap .page-head p {
    font-size: 18px;
    line-height: 30px;
}
/*************** statc pages end *****************/
/*************** pricing page start ****************/
.price-wrapper {
    background-color: #fff;
    border-radius: 20px;
    padding: 50px 30px 5px;
    min-height: 550px;
    border: 2px solid #e6e7e8;
    position: relative;
}
.price-wrapper .plan-name {
    background-color: rgb(22,164,74,.1);
    border-radius: 20px;
    color: rgb(22,164,74);
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    padding: 5px 10px;
    font-weight: 700;
    display: inline-block;
    margin: auto;
    letter-spacing: 1px;
}
.price-wrapper h3 {
    font-family: 'Inter', sans-serif;
    font-size: 20px !important;
    text-transform: uppercase;
    padding: 0;
    font-weight: 600;
    color: #000;
    text-align: center;
}
.price-wrapper .price {
    font-family: 'Inter', sans-serif;
    font-size: 40px !important;
    padding: 0;
    font-weight: 700;
    color: #000;
    text-align: center;
}
.price-wrapper .price span {
    font-size: 16px !important;
    color: #999;
    font-weight: 600;
}

.price-wrapper .service-fee{
    font-family: 'Inter', sans-serif;
    font-size: 14px !important;
    padding: 0;
    color: #999;
    text-align: center;
}
.price-wrapper .btn-primary {    
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(22,164,74,1) 0%, rgba(34,196,94,1) 100%);
    border-radius: 0;
    border: none;
    padding: 10px 55px 10px 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    box-shadow: 7px 7px 9px rgba(0,0,0,.1);
    transition: transform .8s; /* Animation */
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 40px;
    width: calc(100% - 60px);
}
.price-wrapper .btn-primary:hover {
    background: rgb(22,164,74);
    background: linear-gradient(90deg, rgba(34,196,94,1) 0%, rgba(22,164,74,1) 100%);
}
.price-wrapper .btn-primary:after {
    content: "";
    background-image: url('/public/images/indicator-right.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 26px;
    position: absolute;
    right: 15px;
    top: calc(50% - 13px);
}
.price-wrapper ul {
    padding: 30px 0;
    margin: 0;
}
.price-wrapper ul li {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    list-style: none;
    padding: 8px 0;
}
.price-wrapper ul li .glyphicon {
    color: rgb(22,164,74);
    font-size: 14px;
    padding-right: 5px;
}
/*************** pricing page end ****************/
.front-color li a {
    color: #0049ed !important;
}
.contact-form {
    background-color: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
}
.contact-info h4 {
    color: #0049ed !important;
}
.ReactModal__Content .login-content {
    max-height: 600px;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 2px;
}
.loggedin-bg .menu-links {
    flex: inherit;
}
.loggedin-bg .login-button {
    flex: 1;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .form-wrap {
        width: 96%;
        left: 2%;
        padding: 15px;
    }
    .carousel-caption h1 {
        font-size: 30px;
    }
    .carousel-caption h1 > span {
        font-size: 50px;
    }
    .renewal-form-inner {
        width: 90%;
        left: 5%;
    }
    .renewal-form-inner .usp-wrap {
        width: 100%;
    }
    .container {
        width: 100%
    }
}
@media (min-width: 768px) and (max-width: 991px) { 
    .form-wrap {
        width: 96%;
        left: 2%;
        padding: 0;
    }
    .form-wrap .row {
        padding: 0;
        margin: 0;
    }
    .usp-wrap {
        width: 96%;
        left: 2%;
    }
    .form-wrap .form-inline .form-group {
        padding: 20px 10px;
    }
    .form-wrap .form-inline .form-group.selectbox-wrap {
        border-bottom: 2px solid #f2f2f2;
    }
    .form-wrap .form-inline .form-group .invalid-feedback {
        bottom: 5px;
        left: 43px;
    }
    .reg-entry {
        border-top: 2px solid #f2f2f2; 
        padding: 15px 10px;  
        text-align: right;
    }
    .reg-entry .btn-primary {
        max-width: 100px;
    }
    .feature-image h2 {
        padding: 110px 0 0;
    }
    .description-box {
        margin-bottom: 20px;
    }
    .footer-links {
        text-align: center;
    }
    .copyright {
        text-align: center;
        padding-top: 10px;
    }

    .carousel-caption h1 {
        font-size: 20px;
    }
    .carousel-caption h1 > span {
        font-size: 40px;
    }
    .renewal-form-wrap {
        height: auto;
        padding: 30px 0;
    }
    .renewal-form-inner {
        width: 90%;
        position: relative;
        left: 5%;
        top: auto;
        padding: 20px;
    }
    .renewal-form-inner .usp-wrap {
        width: 100%;
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container .custom-select__placeholder {
        /*padding: 15px 0;*/
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__menu {
        margin-top: 0px;
    }
    .mkt-wrap .description-box {
        margin-bottom: 60px;
    }
    .container {
        width: 100%
    }
    .selectbox-wrap .municipality-section {
        margin-top: 0;
    }
    .selectbox-wrap .municipality-section .css-b62m3t-container {
        padding: 9px 0;
    }
    .selectbox-wrap #react-select-3-placeholder {
        padding: 17px 0;   
    }
    .header .navbar-toggle {
        display: block;
    }




    .header {
        display: block;
        padding: 9px 0;
    }
    .header .logo {
        float: left;
    }
    .header .navbar-toggle {
        float: right;
        border: 1px solid #0049ed;
        background-color: #0049ed;
        margin-top: 14px;
        margin-right: 0px;
    }
    .header .navbar-toggle .icon-bar {
        background-color: #fff;
    }
    .header .menu-links {
        display: none !important;
        background-color: #fff;
        border-top: 1px solid #0049ed;
        top: 80px;
        position: absolute;
        left: -15px;
        width: calc(100% + 30px);
        right: 0;
        padding: 0;
    }
    .header .menu-links.collapse.in {
        display: block !important;
    }
    .header .menu-links.collapse.in .mobile-nav {
        float: none !important;
    }
    .header .menu-links.collapse.in .mobile-nav ul {
        float: none !important;
        margin-top: 0;
    }
    .header .menu-links.collapse.in .mobile-nav ul li a {
        padding: 10px;
        border-bottom: 1px solid #0049ed;
    }
    .header .menu-links.collapse.in .login-button {
        float: none !important;
        padding: 5px 10px 10px;
    }
    .header .menu-links.collapse.in .login-button button {
        width: 100%;
        float: none;
    }
    .header .nav.nav-pills {
        padding: 14px;
    }

    .header .nav.nav-pills {
        padding: 5px;
        margin: 8px 10px 0;
        border-radius: 5px;
    }
    .header .menu-links.collapse.in .nav.navbar-nav {
        float: none !important;
        margin: 0;
    }
    .header .menu-links.collapse.in .nav.navbar-nav li {
        padding: 0;
    }
    .header .menu-links.collapse.in .nav.navbar-nav li a {
        padding: 10px 10px;
        border-bottom: 1px solid #0049ed;
        color: #0049ed;
    }
    .menu-links .nav li.dropdown .dropdown-menu {
        padding: 0;
    }
    .menu-links .nav li.dropdown .dropdown-menu li {
        padding: 0 !important;
    }
    .menu-links .nav li.dropdown .dropdown-menu li a {
        color: #666 !important;
        border-color: #e1e1e1 !important;
    }
    .header .nav.nav-pills .dropdown .dropdown-menu {
        top: 59px;
    }
    .navbar-nav > li {
        float: none;
    }
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .analyse-box {
        margin-bottom: 20px;
    }
    .card-inner {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .header {
        position: relative;
    }
    .feature-image {
        height: auto;
    }
    .feature-image h2 {
        font-size: 32px;
        line-height: 40px;
        padding: 50px 0;
    }
    .form-wrap {
        position: relative;
        top: auto;
        bottom: auto;
    }
    .feature-image h2 span {
        font-size: 70px;
    }
    .form-wrap.form-wo-border .form-group .icon {
        top: 9px;
        float: left;
    }
    .form-wrap {
        width: 96%;
        left: 2%;
        padding: 0;
    }
    .form-wrap .row {
        padding: 0;
        margin: 0;
    }
    .usp-wrap {
        width: 90%;
        left: 5%;
        position: relative;
        bottom: auto;
        padding: 30px 0;
    }
    .usp-wrap ul li {
        line-height: 30px;
        padding: 30px 0;
        display: block;
    }
    .usp-wrap ul li br {
        display: none;
    }
    .usp-wrap ul li .svg-inline--fa {
        margin-top: -15px;
    }
    .form-wrap .form-inline .form-group {
        padding: 10px;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px solid #f2f2f2;
        margin-bottom: 0;
    }
    .form-wo-border .form-group .form-control {
        width: calc(100% - 15px);
        float: left;
    }
    .form-wrap .form-inline .form-group.selectbox-wrap {
        border-bottom: 2px solid #f2f2f2;
    }
    .form-wrap .form-inline .form-group .invalid-feedback {
        bottom: 5px;
        left: 43px;
    }
    .reg-entry {
        border-top: none; 
        padding: 15px 10px;  
        text-align: right;
    }
    .reg-entry .btn-primary {
        max-width: 100px;
    }
    .description-box {
        margin-bottom: 20px;
    }

    .footer-links {
        text-align: center;
    }
    .copyright {
        text-align: center;
        padding-top: 10px;
    }
    .row {
        /*margin: 0;*/
    }
    .addr-m-padding .form-group {
        padding: 0;
    }
    .selected-state  .icon {
        top:4px !important;
        margin-right:5px;
    }


    .carousel-caption h1 {
        font-size: 20px;
    }
    .carousel-caption h1 > span {
        font-size: 40px;
    }
    .renewal-form-wrap {
        height: auto;
        padding: 30px 0;
    }
    .renewal-form-inner {
        width: 90%;
        position: relative;
        left: 5%;
        top: auto;
        padding: 20px;
    }
    .renewal-form-inner .usp-wrap {
        width: 100%;
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__control .custom-select__value-container .custom-select__placeholder {
        padding: 20px 0 15px;
    }
    .renewal-form-inner .form-inline .form-group.selectbox-wrap .custom-select__menu {
        margin-top: 0px;
    }
    .mkt-wrap .description-box {
        margin-bottom: 60px;
    }
    .carousel-inner > .item img {
        min-height: 300px;
    }
    .renewal-form-inner .usp-wrap {
        margin: 0;
    }
    .renewal-form-inner .usp-wrap ul li {
        width: 100%;
    }
    .container {
        width: 100%
    }
    .form-wrap .form-inline .form-group.selectbox-wrap {
        padding: 0;
        border-bottom: 2px solid #f2f2f2;
    }
    .selectbox-wrap .municipality-section {
        margin-top: 0;
        flex-direction: column-reverse;
    }
    .selectbox-wrap .municipality-section .css-b62m3t-container,
    .selectbox-wrap .municipality-section .selected-state {
        width: 100%;
    }
    .selectbox-wrap .municipality-section .selected-state {
        margin: 0;
        padding: 13px 0;
        border-right: 0;
    }
    .selectbox-wrap .municipality-section .css-b62m3t-container {
        border-top: 2px solid #f2f2f2;
    }
    .selectbox-wrap .municipality-section .selected-state .icon {
        top: 1px !important;
    }
    #react-select-5-placeholder.custom-select__placeholder {
        padding-top: 15px;
    }
    .css-1dimb5e-singleValue {
        /*padding: 15px 0;*/
    }
    .static-page-wrap {
        z-index: 0;
    }
    .static-page-wrap {
        background-color: #0049ed;
    }
    .static-page-wrap .wrap-img {
        display: none;
    }
    .static-page-wrap .inner-wrapper .white-wrapper {
        margin-bottom: 30px;
        padding: 20px;
    }
    .static-page-wrap .inner-wrapper h1 {
        font-size: 36px;
    }
    .price-wrapper {
        margin: 0 0 20px;
    }
    .contact-info {
        padding: 0 !important;
    }
    .step2-wrap {
        background-color: #0049ed;
        padding-bottom: 50px;
    }
    .step2-wrap .step2-wrap-img {
        display: none;
    }
    .step2 .form-wrapper {
        padding: 20px !important;
    }
    .header .nav.nav-pills {
        padding: 5px;
        margin: 8px 10px 0;
        border-radius: 5px;
    }
    .header .menu-links.collapse.in .nav.navbar-nav {
        float: none !important;
        margin: 0;
    }
    .header .menu-links.collapse.in .nav.navbar-nav li {
        padding: 0;
    }
    .header .menu-links.collapse.in .nav.navbar-nav li a {
        padding: 10px 10px;
        border-bottom: 1px solid #0049ed;
        color: #0049ed;
    }
    .menu-links .nav li.dropdown .dropdown-menu {
        padding: 0;
    }
    .menu-links .nav li.dropdown .dropdown-menu li {
        padding: 0 !important;
    }
    .menu-links .nav li.dropdown .dropdown-menu li a {
        color: #666 !important;
        border-color: #e1e1e1 !important;
    }
    .header .nav.nav-pills .dropdown .dropdown-menu {
        top: 59px;
    }
    .analyse-box {
        margin-bottom: 20px;
    }
    .card-inner {
        width: 100%;
    }
    .tab-buttons .tab-button {
        width: 130px;
        height: 50px;
    }
    .react-tabs__tab {
        padding: 5px 10px !important;
        font-size: 16px;
        border-radius: 0px !important;
        margin: 0 !important;
    }







    
    .header {
        display: block;
        padding: 9px 0;
    }
    .header .logo {
        float: left;
    }
    .header .navbar-toggle {
        float: right;
        border: 1px solid #0049ed;
        background-color: #0049ed;
        margin-top: 14px;
        margin-right: 0px;
    }
    .header .navbar-toggle .icon-bar {
        background-color: #fff;
    }
    .header .menu-links {
        display: none !important;
        background-color: #fff;
        border-top: 1px solid #0049ed;
        top: 80px;
        position: absolute;
        left: -15px;
        width: calc(100% + 30px);
        right: 0;
        padding: 0;
    }
    .header .menu-links.collapse.in {
        display: block !important;
    }
    .header .menu-links.collapse.in .mobile-nav {
        float: none !important;
    }
    .header .menu-links.collapse.in .mobile-nav ul {
        float: none !important;
        margin-top: 0;
    }
    .header .menu-links.collapse.in .mobile-nav ul li a {
        padding: 10px;
        border-bottom: 1px solid #0049ed;
    }
    .header .menu-links.collapse.in .login-button {
        float: none !important;
        padding: 5px 10px 10px;
    }
    .header .menu-links.collapse.in .login-button button {
        width: 100%;
        float: none;
    }
}

.coming-soon {
    color: #777 !important; 
    font-weight: bold;
}

.coming-soon::after {
    content: " - Coming Soon";
    color: #d9534f;
    margin-left: 5px;
}

.contactus_text a , .contactus_text svg  {
    font-size:  1.5em;
    padding-right: 5px;
}